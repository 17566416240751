<template>
  <v-app>
    <Nav v-if="this.$store.state.Auth.access" />
    <v-main>
      <div name="snackbars">
        <v-snackbar
          v-model="show"
          :color="color"
          :timeout="timeout"
          bottom
          right
          elevation="24"
        >
          {{ text }}
          <template v-slot:action="{ attrs }">
            <v-btn dark text v-bind="attrs" @click="show = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Nav from "@/components/Nav.vue";
import { mapActions } from "vuex";
export default {
  name: "App",
  components: {
    Nav,
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        console.log(to, from);
        document.title = to.meta.title || "Gimbooks Partners";
      },
    },
  },
  methods: {
    ...mapActions("Auth", ["getCounts"]),
  },
  data: () => ({
    show: false,
    color: "",
    text: "",
    timeout: -1,
  }),
  mounted() {
    console.log(this.$store.state.Auth.staging_url)
    console.log(`${process.env.APP_ENVIRONMENT==='staging'?this.$store.state.Auth.staging_url:''}`, 'djbdjb');
    console.log(process.env.APP_ENVIRONMENT, 'djne')
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      console.log("login snackbar");
      if (mutation.type === "snackbar/SHOW_MESSAGE") {
        this.text = state.snackbar.text;
        this.color = state.snackbar.color;
        this.timeout = state.snackbar.timeout;
        this.show = true;
      }
    });
  },
};
</script>
