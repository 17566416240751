<template>
  <div style="background-color:#F2F2F2;height:91vh;">
  	<span v-if="!token">{{goToLogin()}}</span>
	<v-container-fluid >
		
		<v-row style="padding:40px 100px;">
			<v-col cols="4" col="4" v-for="item in arr" :key="item.title">
				<!-- <router-link style="cursor:pointer;"> -->
					<v-card  :href="item.title==='Total Commission Earned'? '/#/team-commission': ''"  height="140" style="cursor:pointer;padding:2px 20px;">
						<div style="height:40px;margin-bottom:45px;">
							<h1 style="font-size:50px;padding:5px;font-family: Proxima Nova, sans-serif;font-weight: bold;
	font-size: 56px;font-style: normal;font-weight: bold;"> 
							<span v-if="item.value==='com'">₹</span> {{item.amount}}</h1>
						</div>
						<div style="display:flex;padding:10px;" >
							
							<span style="padding-top:10px;font-size:14px;font-weight:450;">{{item.title}}</span>
							<v-spacer></v-spacer>
							<img :src="item.src" alt="hello" height="30" width="50">
						</div>
					</v-card>
				<!-- </router-link> -->
			</v-col>
		
		</v-row>
	</v-container-fluid >
		
  </div>
</template>

<script>
import {mapActions} from  "vuex";

export default {
  name: "Home",
  data(){
	return {
		items:[
			{title:"of Referrals", link:"/", src:require('@/assets/referral.svg'), amount:`${this.total_ref}`},
			{title:"of Leads Subscribed", link:"/",  src:require('@/assets/file.svg'), amount:`${this.$store.state.Auth.total_subs_count}`},
			{title:"Total Commission Earned", link:"/team-commission", src:require('@/assets/salary.svg'), amount:`${this.$store.state.Auth.total_comm}`},
			{title:"SDR's", link:"/", src:require('@/assets/man.svg'), amount:`${this.$store.state.Auth.sd_count}`},
			{title:"Partners", link:"/", src:require('@/assets/team.svg'), amount:`${this.$store.state.Auth.pt_count}`},
		]
	}
  },
  methods:{
  	...mapActions('Auth', ['getCounts', 'getAdminCounts']),
  	goToLogin() {  this.$router.push({name:'Login'})} 

  },
  computed:{
      token(){
        if(localStorage.getItem('access')){
          return true
        }
        else{
          return false
        }
      },
	//   total_ref(){
	// 	  return this.$store.getters('Auth/get_total_ref')
	//   },
	  arr(){
		  return 	[
			{title:"of Referrals", src:require('@/assets/referral.svg'), amount:`${this.$store.state.Auth.total_ref}`, value:'ref'},
			{title:"of Leads Subscribed",  src:require('@/assets/file.svg'), amount:`${this.$store.state.Auth.total_subs_count}`, value:'subs'},
			{title:"Total Commission Earned", src:require('@/assets/salary.svg'), amount:`${this.$store.state.Auth.total_comm}`, value:'com' },
			{title:"SDR's", src:require('@/assets/man.svg'), amount:`${this.$store.state.Auth.sd_count}`, value:'sdr'},
			{title:"Partners", src:require('@/assets/team.svg'), amount:`${this.$store.state.Auth.pt_count}`, value:'p'},
		]
	
	  }

    },
  created(){
  	if(localStorage.getItem('user_type')==='ADMIN'){
				console.log('admim')
		
		this.getAdminCounts()
		
	}else{
		setTimeout(()=>{
			this.getCounts()
		}, 1000)
		// this.getCounts
	}

  },
 
};
</script>
