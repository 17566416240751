<template>
	<div>
		 <v-navigation-drawer v-if="sidebar" v-model="sidebar" app>
	      <v-list >
	        <router-link
	          class="activelink mt-1 p-2"
	          style="padding:15px;text-align:center;display:block;cursor:pointer;text-decoration:none;color:black;"
	          flat
	          v-bind:name="item.title"
	          v-for="item in menuItems"
	          :key="item.title"
	          :to="item.path"
	          
	          >
	          <!-- <v-icon left dark>{{ item.icon }}</v-icon> -->
	          {{ item.title }}

	        </router-link>
	      </v-list>
	    </v-navigation-drawer>
	    <v-app-bar app style="background-color:white;" elevation="1">
	      <span class="hidden-sm-and-up">
	        <v-app-bar-nav-icon  @click="sidebar = !sidebar"><v-icon>mdi-menu</v-icon>
	        </v-app-bar-nav-icon>
	      </span>
	      <v-app-bar-title class="pb-2 fix2">
	        <router-link to="/" tag="span" style="cursor: pointer">
	          <v-img src="@/assets/gimbook_logo.svg" style="max-width:180px; max-height:220px;" ></v-img>
	        </router-link>
	      </v-app-bar-title>
	      <!-- <v-spacer></v-spacer> -->
	      <v-toolbar-items class="hidden-s-only fix pt-4  ma-5" style="width:100% !important;">
	        <div style="min-width:70% !important;margin-left:30px;margin-top:15px; ">
	        <router-link
	          class="activelink mt-4  "
	          style="width:100%;cursor:pointer;color:black;text-decoration:none;outline: none;white-space: nowrap;"
	          v-bind:name="item.title"
	          v-for="item in menuItems"
	          :key="item.title"
	          :to="item.path"
	          >

	          <span style="padding:10px !important;">{{ item.title }}
	          	</span>
	        </router-link>
	       	</div>
	        <!-- <v-text-field
	        	prepend-inner-icon="mdi-magnify"
	        	background-color="#F2F2F2"
	        	height="35"
	          style="width:100px !important;border:1px solid #F2F2F2;max-height:35px  !important;float:right;outline:none;"
	          class="input-field"
	          filled
	          dense

	        ></v-text-field> -->
			<span class="pa-4"><strong>Welcome </strong>{{username}}</span>
	        <v-menu
	        bottom
	        style="margin:5px;float:right; "
	        min-width="220px"
	        rounded
	        offset-y

	      >
	        <template v-slot:activator="{ on }">
	            <div style="padding:2px;display:flex;margin-bottom:4px;">
	            <v-avatar
	              color="#2F80ED"
	              size="35"
	              v-on="on"
	              style="cursor:pointer;"
	            >
	              <span class=" white--text" >{{initials}}</span>
	              </v-avatar>
	              <v-icon small>mdi-chevron-down</v-icon>
	              </i>
	            </div>
	        </template>
	        <v-card>
	          <v-list-item-content class="justify-center">
	            <div class="mx-auto text-center">
	              <p
	                style="cursor:pointer;margin:1px;font-size:14px;"
	                text
	                @click="onLogout()"
	              >
	               LOGOUT
	              </p>
	            </div>
	          </v-list-item-content>
	        </v-card>
	      </v-menu>
      </v-toolbar-items>
    </v-app-bar>
	</div>

</template>
<script>
import {mapActions} from  "vuex";
	export default {
		name:'Nav',
		data(){
			return {
				sidebar: false,
		        initials:'',
		        menuItems: [ 
		          { title: 'Dashboard', path: '/', perm: ''},
		          // { title: 'Referrals', path: '/referrals', perm: 'has_gold_refining_access' },
		          { title: 'Referrals', path: '/referrals' },
		          { title: 'Team', path: '/team', perm: 'has_assaying_page_access'},
		          // { title: 'Team ', path: '/gold-coins'},
		          { title: 'Payouts', path: '/payouts', perm:'has_hallmarking_perm' },
		          { title: 'My Commission', path: '/team-commission', perm:'has_hallmarking_perm' },
		          
		     ]
			}
		},
		methods:{
			 ...mapActions('Auth',['logout']),
			onLogout(){
	          this.logout();
	          this.$router.push({name:'Login'})

	        },
		},
		mounted(){
			console.log(this.$store.state.Auth.username)
	      const x = `${localStorage.getItem('name')}`.trim().split(" ")

	      console.log(x, 'tjos')
	      if (x[1]){
	      this.initials = x[0].charAt(0).toUpperCase() + x[1].charAt(0).toUpperCase()
	      }else{
	      this.initials = x[0].charAt(0).toUpperCase()
	        
	      }console.log(this.initials, x)
		},
		computed:{
			username(){return localStorage.getItem('name')}
		}
	}
</script>


<style scoped>
h3 {
  margin: 40px 0 0;
}
.router-link-exact-active{
  border-bottom:5px solid #2F80ED;
  padding-bottom:9px;
  margin-bottom:6px;
	
  border-outset:  5px / 10px;
  color:#2F80ED !important;
}

ul {
  list-style-type: none;
  padding-left: 10px;

}
li {
  float:right;
  margin: 0 20px;
  padding:2px;
}
a {

  color: #42b983;
}
@media only screen and (min-width> 970px) {
  .fix {
    margin-left:50px;
  }
  .fix2{
    margin:10px;
  }
}




</style>