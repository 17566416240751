import axios from "axios";
import api from "@/helper-axios.js";

const state = {
  // staging_url: "https://staging.gimbooks.com",
  staging_url: "https://gimbooks.com",
  // staging_url: 'http://localhost:8000',
  tokens: [],
  isAuthenticated: false,
  notify: "",
  uid: "",
  phone: "",
  userphone: localStorage.getItem("phone") || null,
  username: localStorage.getItem("username") || null,
  refresh: localStorage.getItem("refresh"),
  access: localStorage.getItem("access") || null,
  errorMessage: "",
  success: "",
  snackbarShowing: true,
  snackbarText: "Hello ",
  hd_count: 0,
  sd_count: 0,
  pt_count: 0,
  ie_count: 0,
  su_count: 0,
  nsu_count: 0,
  nsub_count: 0,
  regular_sub_count: 0,
  e_invoice_count: 0,
  gst_count: 0,
  e_waybill_count: 0,
  loan_count: 0,
  total_ref: 0,
  success_count: 0,
  cancel_count: 0,
  total_subs_count: 0,
  total_comm: 0,
};
const actions = {
  loginUser({ dispatch, commit, state }, phone, body) {
    // console.log(phone, "in actions");

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(`${state.staging_url}/api/login/`, phone, config)
      .then((res) => {
        console.log(res.data);
        commit("loginUser", res.data);
        localStorage.setItem("access", res.data["token"]);
        // localStorage.setItem("refresh", res.data['refresh']);
        localStorage.setItem("user_type", res.data["user"]["role"]);
        localStorage.setItem("name", res.data["user"]["name"]);
        localStorage.setItem("uid", res.data["user"]["id"]);
        // console.log('started setting token')
        dispatch(
          "snackbar/showSnack",
          {
            text: "Login Successfull ",
            color: "success",
            timeout: 1500,
          },
          { root: true }
        );
      })
      .catch((err) => {
        console.log(err, "err");
        console.log(err.response.data, "errrpr");
        commit("setError", err.response.data.detail);
        dispatch(
          "snackbar/showSnack",
          {
            text: `${err.response.data.non_field_errors[0]}`,
            color: "error",
            timeout: 4500,
          },
          { root: true }
        );
      });
    // console.log(response.data);
  },
  resetPassword({ dispatch, commit, state }, phone) {
    // console.log(phone, "in actions");
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(`${state.staging_url}/api/team/reset/`, phone, config)
      .then((res) => {
        // console.log(res.data, "res");
        commit("resetPassword", res.data);
        dispatch(
          "snackbar/showSnack",
          {
            text: `Password Reset Link Sent to ${phone["phone"]}`,
            color: "success",
            timeout: 4500,
          },
          { root: true }
        );
      })
      .catch((err) => {
        // console.log(err.response.data, "error");
        dispatch(
          "snackbar/showSnack",
          {
            text: `Error Sending Password Reset Link to ${phone["phone"]}`,
            color: "error",
            timeout: 4500,
          },
          { root: true }
        );
      });
  },
  resetPasswordConfirm({ dispatch, commit, state }, uid) {
    console.log(uid, "in actions");
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    axios
      .patch(
        `${state.staging_url}/api/team/reset-password-confirm/`,
        uid,
        config
      )
      .then((res) => {
        // console.log(res.data);
        commit("resetPasswordConfirm", "success");
        dispatch(
          "snackbar/showSnack",
          {
            text: "Reset Passsword Successfull",
            color: "success",
            timeout: 4500,
          },
          { root: true }
        );
      })
      .catch((err) => {
        console.log(err.response.data);
        dispatch(
          "snackbar/showSnack",
          {
            text: "Error Resetting Password",
            color: "error",
            timeout: 4500,
          },
          { root: true }
        );
      });
  },
  getCounts({ commit, state }) {
    const uid = localStorage.getItem("uid");
    const token = localStorage.getItem("access");
    const config={
      headers:{
        Accept: "application/json",
        "Content-Type":"application/json",
        "Authorization" : `jwt ${token}`
      }
    }
    axios
      .get(`${state.staging_url}/api/team/count/${uid}/`, config)
      .then((res) => {
        // consol/e.log(res.data);
        // console.log('count was given')
        commit("SET_COUNTS", res.data);
      })
      .catch(
        (error) => console.log(error)
        // {}
      );
  },
  getAdminCounts({ commit, state }) {
    const uid = localStorage.getItem("uid");
    const token = localStorage.getItem("access");
    const config={
      headers:{
        Accept: "application/json",
        "Content-Type":"application/json",
        "Authorization" : `jwt ${token}`
      }
    }
    axios
      .get(`${state.staging_url}/api/team/count/${uid}/`, config)
      .then((res) => {
        // consol/e.log(res.data);
        console.log("admin count was given");
        commit("SET_COUNTS", res.data);
      })
      .catch(
        (error) => console.log(error)
        // {}
      );
  },
  logout({ commit }) {
    // commit("logout");
    localStorage.removeItem("access");
    // localStorage.removeItem("refresh");
    localStorage.removeItem("user_type");
    localStorage.removeItem("name");
    localStorage.removeItem("name");
    localStorage.removeItem("uid");
    localStorage.removeItem("phone");

    state.access = "";
    state.username = "";
    state.refresh = "";

    // console.log("looged out ")
    commit("setAuthenticated", false);
  },
};
const getters = {
  get_total_ref(state) {
    return state.total_ref;
  },
};
const mutations = {
  loginUser: (state, payload) => {
    console.log(payload, "aijidn");
    state.tokens = payload;
    state.access = payload["token"];
    // state.refresh = payload["refresh"];
    state.isAuthenticated = true;
  },
  setAuthenticated: (state, value) => {
    state.isAuthenticated = false;
    state.tokens = [];
  },
  resetPassword: (state) => (state.tokens = []),
  resetPasswordConfirm: (state) => (state.success = "success"),
  logout: (state) => {
    state.tokens.access = "";
    state.tokens.refresh = "";
  },
  setError: (state, errorMessage) => (state.errorMessage = errorMessage),
  SET_COUNTS: (state, payload) => {
    console.log(payload);
    state.ie_count = payload.ie_count;
    state.pt_count = payload.pt_count;
    state.sd_count = payload.sd_count;
    state.hd_count = payload.hd_count;
    state.su_count = payload.su_count;
    state.nsu_count = payload.nsu_count;
    state.nsub_count = payload.nsub_count;
    state.total_ref = payload.total_ref;
    state.cancel_count = payload.cancel_count;
    state.success_count = payload.success_count;
    state.total_subs_count = payload.total_subs_count;
    state.total_comm = payload.total_comm;
    state.regular_sub_count = payload.regular_sub_count;
    state.e_invoice_count = payload.e_invoice_count;
    state.gst_count = payload.gst_count;
    state.e_waybill_count = payload.e_waybill_count;
    state.loan_count = payload.loan_count;
    console.log(state, "state");
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
