import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import api from "./helper-axios.js";

Vue.config.productionTip = false
Vue.prototype.$http = api; 
api.defaults.timeout = 10000;


api.interceptors.request.use(
  config => {
    const token = localStorage.getItem("access");
    console.log('token from main.js' , token)
    if (token) {
      config.headers.common["Authorization"] =  `jwt ${token}`;
    }
    console.log(`Bearer ${token}`)
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          break;
        case 401:
        console.log(`${localStorage.getItem('access')}`, 'token')
          console.log(error, 'error')
          store.dispatch("snackbar/showSnack", {
	          text: "Token Expired, Please Login Again",
	          color: 'error',
	          timeout: 3500,
	        }, {root:true}) 
          router.push('login')
          store.dispatch("Auth/logout", {root:true}) 
          break;        
        case 403:
          router.replace({
            path: "/login",
            query: { redirect: router.currentRoute.fullPath }
          });           
          break;        
        case 404:
          store.dispatch("snackbar/showSnack", {
	          text: "Page Not Found",
	          color: 'error',
	          timeout: 3500,
	        }, {root:true}) 
          break; 
        case 500:
         console.log(error.response.data['non_field_errors'])
          store.dispatch("snackbar/showSnack", {
            text: "Internal Server Occured",
            color: 'error',
            timeout: 3500,
          }, {root:true}) 
          break;        
        case 502:
         setTimeout(() => {
            router.replace({
              path: "/login",
              query: {
                redirect: router.currentRoute.fullPath
              }
            });
          }, 1000);

      }
      return Promise.reject(error.response);
    }
  }
);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
