import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      guest: true,
      title: 'Dashboard',
    },
  },

  {
    path: "/referrals",
    name: "Referrals",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Referrals.vue"),
    meta: {
      guest: true,
      title: 'Referrals',
    },
  },
  {
    path: "/payouts",
    name: "Payout",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Payout.vue"),
    meta: {
      guest: true,
      title: 'Payouts',
    },
  },
   {
    path: "/team",
    name: "Team",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Team.vue"),
    meta: {
      guest: true,
      title: 'Team',
    },
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
    meta: {
      guest: true,
      title: 'Login',
    },
  },{
    path: "/password-reset",
    name: "PasswordReset",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "PasswordReset" */ "../views/PasswordReset.vue"
      ),
    meta: {
      guest: true,
      title:'Password Reset'
    },
  },
  {
    path: "/reset/:uid/:token",
    name: "PasswordResetConfirm",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "PasswordResetConfirm" */ "../views/PasswordResetConfirm.vue"
      ),
    meta: {
      guest: true,
      title:'Password Reset Confirm'
    },
  },
  {
    path: "/team-commission",
    name: "TeamCommissionTable",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "Teamcommission" */ "../views/TeamCommissionTable.vue"
      ),
    meta: {
      guest: true,
      title:'Commission History'
    },
  },
  {
    path: "/reset-success",
    name: "ResetSuccessfull",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "ResetSuccessfullCard" */ "../components/ResetSuccessfullCard.vue"
      ),
    meta: {
      guest: true,
      title:"Password Reset Successfull"
    },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
